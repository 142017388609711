import styled from "styled-components";
import AdImageLarge from "../../img/page-ad-image-768.png";
import AdImageSmall from "../../img/page-ad-image-384.png";

const AdWrap = styled.div`
  margin-left: auto;
  margin-top: ${({ theme }) => theme.space[1]};
  margin-right: auto;
  margin-bottom: ${({ theme }) => theme.space[6]};
`;

const PageAdImage = styled.div`
  background-image: url(${AdImageSmall});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 49px;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    background-image: url(${AdImageLarge});
    height: 99px;
  }
`;

const PageAd = () => {
  return (
    <AdWrap>
      <PageAdImage />
    </AdWrap>
  );
};

export default PageAd;
