import orderBy from "lodash/orderBy";
import { useTranslation } from "react-i18next";
import { Box } from "rebass";
import styled from "styled-components";
import { IElement } from "../../core/store/elements/types";
import { integerToDecimal } from "../../utils/decimal";
import Badge from "../Badge";
import {
  ElementCardBadge,
  ElementCardMain,
  ElementCardTeam,
  ShirtWrap,
} from "../ElementCard";
import { PitchElementInner } from "../Pitch";
import { ElementName, StyledPitchElementData } from "../PitchElementData";
import Shirt from "../Shirt";
import ElementGroup from "../squad/ElementGroup";
import SubHeading from "../SubHeading";
import TeamCode from "../TeamCode";

const StyledElementName = styled(ElementName)`
  text-align: center;
`;

const Score = styled.div<{ elementTypeId: number }>`
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colors.elementTypes[props.elementTypeId].colors[0]}
      75%,
    ${(props) => props.theme.colors.elementTypes[props.elementTypeId].colors[1]}
  );
  border-bottom-right-radius: ${(props) => props.theme.radii[2]};
  border-bottom-left-radius: ${(props) => props.theme.radii[2]};
  padding: ${(props) => props.theme.space[1]};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.action};
  font-size: 2.5rem;
  text-align: center;
`;

const Rank = styled.div`
  align-self: center;
  padding: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.action};
  font-size: 2.2rem;
  font-style: italic;
`;

const CardWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 0 1 140px;
  max-width: 140px;
  gap: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[1]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin: ${(props) => props.theme.space[0]};
  }
`;

const StyledSubHeading = styled(SubHeading)`
  text-transform: uppercase;
`;

const TopScorers = ({ elements }: { elements: IElement[] }) => {
  const { t } = useTranslation();
  return (
    <>
      <StyledSubHeading>
        {t("status.topScorers", "Top Gameday Performers")}
      </StyledSubHeading>
      <Box mb={4}>
        <ElementGroup
          elementTypeId={elements[0].element_type}
          $showBackground={false}
          $justifyContentCenter={false}
        >
          {orderBy(elements, ["event_points", "now_cost"], ["desc", "desc"])
            .slice(0, 5)
            .map((element: IElement, index: number) => (
              <CardWrap key={element.id}>
                <Rank>{index + 1}</Rank>
                <PitchElementInner>
                  <ElementCardMain>
                    <ElementCardTeam>
                      <ElementCardBadge>
                        <Badge teamId={element.team} isPresentation={true} />
                      </ElementCardBadge>
                      <Box mr={1}>
                        <TeamCode teamId={element.team} />
                      </Box>
                    </ElementCardTeam>
                    <ShirtWrap>
                      <Shirt elementId={element.id} />
                    </ShirtWrap>
                    <StyledPitchElementData
                      elementTypeId={element.element_type}
                    >
                      <StyledElementName>
                        {Array.from(element.first_name)[0]}.
                        <strong>{element.second_name}</strong>
                      </StyledElementName>
                    </StyledPitchElementData>
                    <Score elementTypeId={element.element_type}>
                      {integerToDecimal(element.event_points, 10)}
                    </Score>
                  </ElementCardMain>
                </PitchElementInner>
              </CardWrap>
            ))}
        </ElementGroup>
      </Box>
    </>
  );
};
export default TopScorers;
