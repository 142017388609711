import styled from "styled-components";
import SubHeading from "./SubHeading";
import { useTranslation } from "react-i18next";
import { getSite } from "../utils/sites";

const HomeSubHeading = styled(SubHeading)`
  color: inherit;
  font-family: ${(props) => props.theme.fonts.action};
  font-size: 6.5rem;
  font-style: italic;
  text-transform: uppercase;
`;

const VideoEmbed = styled.div`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => `${theme.space[4]} ${theme.space[2]}`};
`;

const PromoVideoSection = styled.div`
  margin: ${({ theme }) =>
    `${theme.space[5]} ${theme.space[2]} ${theme.space[3]}`};
  text-align: center;
`;

const PromoVideo = () => {
  const { t } = useTranslation();
  const site = getSite();

  const srcUrl =
    site === "main_br"
      ? "https://www.youtube.com/embed/Y9bwLu7JWZk?si=CEFJ47PthA5NPk24"
      : "https://www.youtube.com/embed/z3ZBZ_43oIE?si=pl6weGD66BDhnnRc";

  return (
    <PromoVideoSection>
      <HomeSubHeading>{t("home.videoHeadingn", "how to play")}</HomeSubHeading>
      <VideoEmbed>
        <iframe
          width="560"
          height="315"
          src={srcUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </VideoEmbed>
    </PromoVideoSection>
  );
};

export default PromoVideo;
