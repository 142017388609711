import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { SelectField } from "./FieldRenderers";
import { PrimaryGradient } from "./Utils";
import FdjDisclaimer from "./FdjDisclaimer";
import i18n from "../i18n";

const FieldWrap = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.space[4]} 0;
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 25%;
  }
  &&& > label > span {
    color: ${(props) => props.theme.colors.white};
    font-size: 1.1rem;
  }
`;

const StyledFooterOuter = styled.footer`
  ${PrimaryGradient}
`;

const StyledFooterInner = styled.div`
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 ${(props) => props.theme.space[4]};
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 0 0;
`;

const FooterLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
  margin: 2rem 0;
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex-wrap: nowrap;
    width: 60%;
  }
`;

const FooterLink = styled.a`
  color: ${(props) => props.theme.colors.white};
  font-family: inherit;
  font-size: 1.1rem;
  font-weight: 400;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

const FooterVerticalDivider = styled.div`
  border-left: 1px solid ${(props) => props.theme.colors.white};
  margin: 0 1.3rem;
`;

const FooterTextHeavy = styled.span`
  color: ${(props) => props.theme.colors.white};
  font-size: 1.1rem;
  font-weight: 500;
`;

const FooterText = styled.span`
  color: ${(props) => props.theme.colors.white};
  font-size: 1.1rem;
  font-weight: 400;
`;

interface IProps {}

const handleFilterChange = (url: string) => {
  window.location.href = url;
};

const baseURL = "nbafantasy.nba.com";
const scheme = "https://";
const locales = [
  { countryCode: "gb", title: "English (GB)", url: `${scheme}${baseURL}` },
  { countryCode: "us", title: "English (US)", url: `${scheme}en.${baseURL}` },
  { countryCode: "de", title: "German", url: `${scheme}de.${baseURL}` },
  { countryCode: "fr", title: "French", url: `${scheme}fr.${baseURL}` },
  { countryCode: "es", title: "Spanish", url: `${scheme}es.${baseURL}` },
  { countryCode: "it", title: "Italian", url: `${scheme}nbafantasy.sky.it` },
  { countryCode: "gr", title: "Greek", url: `${scheme}gr.${baseURL}` },
  { countryCode: "tr", title: "Turkish", url: `${scheme}tr.${baseURL}` },
  {
    countryCode: "he",
    title: "Hebrew",
    url: `${scheme}nbafantasy.sport5.co.il`,
  },
  {
    countryCode: "br",
    title: "Portuguese (BR)",
    url: `${scheme}br.${baseURL}`,
  },
];

const Footer: React.FC<IProps> = () => {
  const { t } = useTranslation();
  return (
    <>
      <StyledFooterOuter>
        <StyledFooterInner>
          <FooterContent>
            <FooterTextHeavy>
              <>&#169;</>
              {t(
                "footer.copyrightn",
                "2023 NBA Media Ventures, LLC. All rights reserved."
              )}
            </FooterTextHeavy>
            <FooterLinkWrapper>
              <FooterLink
                href="https://www.nba.com/privacy-policy"
                target="_blank"
              >
                {t("footer.privacy", "Privacy Policy")}
              </FooterLink>
              <FooterVerticalDivider />
              <FooterLink href="https://www.nba.com/termsofuse" target="_blank">
                {t("footer.terms", "Terms of Use")}
              </FooterLink>
              <FooterVerticalDivider />
              <FooterLink
                href="https://www.nba.com/accessibility"
                target="_blank"
              >
                {t("footer.a11y", "Accessibility and Closed Captions")}
              </FooterLink>
              <FooterVerticalDivider />
              <FooterLink
                href="https://privacyportal.onetrust.com/webform/4b9fe937-73ed-43ed-863a-241ac6bfb482/784f7dd4-5b8b-4569-8087-4560abeab57e"
                target="_blank"
              >
                {t("footer.personal", "Do Not Sell My Personal Information")}
              </FooterLink>
              <FooterVerticalDivider />
              <FooterLink
                href="https://support.watch.nba.com/hc/en-us"
                target="_blank"
              >
                {t("footer.support", "Customer Support")}
              </FooterLink>
            </FooterLinkWrapper>
            <FooterText>
              <Trans i18nKey="footer.a11y2">
                If you are having difficulty accessing any content on this
                website, please visit our{" "}
                <FooterLink
                  href="https://www.nba.com/accessibility"
                  target="_blank"
                >
                  Accessibility page.
                </FooterLink>
              </Trans>
            </FooterText>
          </FooterContent>
          <form>
            <FieldWrap>
              <SelectField
                id="filter"
                name="filter"
                label={t("footer.pickCountry", "Pick your country/ language")}
                value={window.location.origin}
                onChange={(e) => handleFilterChange(e.target.value)}
                required={false}
              >
                {locales.map((locale) => (
                  <option
                    key={locale.countryCode}
                    value={locale.url}
                    aria-selected={window.location.origin === locale.url}
                  >
                    {t(`footer.${locale.countryCode}`, locale.title)}
                  </option>
                ))}
              </SelectField>
            </FieldWrap>
          </form>
        </StyledFooterInner>
      </StyledFooterOuter>
      {i18n.language === "fr" && <FdjDisclaimer />}
    </>
  );
};

export default Footer;
